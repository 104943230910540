<template>
  <TopBar />
  <div class="tabs-container">
    <div class="tabs">
      <!-- Remove existing buttons related to logs and prompts -->
      <!-- New button for SysAmd -->
      <button @click="showSysAmd = true" style="background-color: #000; color: black; margin-left: 20px;">Manage AMD</button>
    </div>
    <div class="tab-content">
      <!-- Remove existing content related to logs and prompts -->
      <!-- Conditionally render SysAmd component -->
      <SysAmd v-if="showSysAmd" />
    </div>
  </div>
  <!-- Remove placeholder information -->
</template>

<script setup>
import { ref } from "vue";
import TopBar from "./TopBar.vue";
import SysAmd from "./SysAmd.vue";
import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const showSysAmd = ref(false); // State to control the visibility of SysAmd


</script>

<style scoped>
/* Remove styles related to logs and prompts */
.tabs-container {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
