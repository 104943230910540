<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <div class="grid-table-cell-wide column">
        <div class="call-to-selector-container">
          <TheSelector ref="callToSelector" />
        </div>
        <img class="cassette-img cassette" src="/tape_blank.png" @click="triggerDropdown" />
      </div>
      <NavBar class="fixed-nav-bar" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import TheSelector from "./TheSelector.vue";
import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const { caller_id, caller_name, caller_phone_number, callee_id, callee_name, callee_phone_number, BACK_URL } = storeToRefs(settings);

const router = useRouter();
const showModal = ref(false);
const showDropdown = ref(false);
const callToSelector = ref(null); 

const triggerDropdown = () => {
  if (callToSelector.value) {
    callToSelector.value.showDropdown();
  }
};

onMounted(async () => {
  await settings.initialize();
  await settings.fetchSettings();

  // if (!settings.callee_id) {
  //   await settings.setEarliestCallee();
  // }
  // if (!settings.caller_id) {
  //   await settings.setEarliestCaller();
  // }

  window.scrollTo(0, 0);
});

</script>

<style scoped>

.cassette-img {
  max-width: 95%;
  max-height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.call-to-selector-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 67px;
  width: 90%;
}

@media (min-width: 600px) {
  .call-to-selector-container {
    top: 95px;
    font-size: 2.5vmin;
  }
}

@media (orientation: landscape) {
  .cassette-img {
    /* display: none; */
  }
}

/* 
.grid-table {
  position: relative;
  height: 100vh;
} */

.fixed-nav-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px; 
  /* border-top-left-radius: 18px;
  border-top-right-radius: 18px; */
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  /* box-shadow: 1px -8px 15px rgb(0, 0, 0, 0.75); */

  background-color: white;
  z-index: 1000;
}

body {
  overflow: hidden;
}

.grid-table {
  height: 100vh;
}



</style>
