<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div class="bottom-margin"></div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Caller Name" v-model="caller.name" />
      </div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Instagram Username" v-model="caller.ig_username" />
      </div>
      <div class="grid-table-cell-wide row">
        <div style="display: flex; justify-content: space-between; width: 100%">
          +1 &nbsp;
          <input 
            type="tel" 
            placeholder="212" 
            v-model="caller.area_code" 
            maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" 
            title="Only numeric values are allowed." 
          />
          <input 
            type="tel" 
            placeholder="555" 
            v-model="caller.prefix" 
            maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" 
            title="Only numeric values are allowed." 
          />
          <input 
            type="tel" 
            placeholder="1234" 
            v-model="caller.suffix" 
            maxlength="4"
            style="width: 38%; text-align: center" 
            title="Only numeric values are allowed." 
          />
        </div>
      </div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="EL Voice ID" v-model="caller.el_voice_id" />
      </div>

      <!-- <div class="grid-table-cell-wide column">
        {{ settings.formatUIString(ui_string.caller_instruct_title, { caller_name: caller.name }) }}
        <textarea :placeholder="ui_string.caller_instruct_default" v-model="caller.caller_instruct" rows="5"></textarea>
      </div> -->

      <div class="grid-table-cell-narrow column">
        <button @click="setToMakeCalls">{{ ui_string.caller_set_button }}</button>
      </div>

      <!-- <div class="grid-table-cell-wide column">
        <label class="sms-checkbox-container">
          <input type="checkbox" :checked="caller.sms_caller === 15" @change="toggleSmsCaller" class="sms-checkbox">
          <span>{{ settings.formatUIString(ui_string.sms_caller_after, { 'caller_name': caller.name }) }}</span>
        </label>
      </div> -->

      <div class="grid-table-cell-narrow column">
        <button @click="callerUpdate">{{ui_string.caller_update_save_button}}</button>
      </div>
      <div class="grid-table-cell-narrow column">
        <button @click="callerDelete" class="delete-button">{{ui_string.caller_update_delete_button}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, BACK_URL, caller_id, caller_name, caller_phone_number, caller_instruct, ui_string } = storeToRefs(settings);
const callerId = ref(router.currentRoute.value.params.caller_id);
const caller = ref({});

const callerGetById = async () => {
  showModal.value = true;
  try {
    const response = await axios.get(`${BACK_URL.value}/caller/caller_get_byid/${callerId.value}`);
    caller.value = response.data;
    if (caller.value.phone_number) {
      const phone = caller.value.phone_number;
      caller.value.area_code = phone.substring(2, 5);
      caller.value.prefix = phone.substring(5, 8);
      caller.value.suffix = phone.substring(8, 12);
    }
  } catch (error) {
    console.error('Error fetching caller:', error.response?.data?.detail || error.message);
  } finally {
    showModal.value = false;
  }
};

const callerUpdate = async () => {
  showModal.value = true;
  const areaCodeValid = /^\d{3}$/.test(caller.value.area_code);
  const prefixValid = /^\d{3}$/.test(caller.value.prefix);
  const suffixValid = /^\d{4}$/.test(caller.value.suffix);

  if (!areaCodeValid || !prefixValid || !suffixValid) {
    alert("Please ensure the area code and prefix have exactly 3 digits, and the suffix has exactly 4 digits, all numeric.");
    showModal.value = false;
    return;
  }

  try {
    const updatedCaller = {
      ...caller.value,
      phone_number: `+1${caller.value.area_code}${caller.value.prefix}${caller.value.suffix}`
    };

    const response = await axios.put(`${settings.BACK_URL}/caller/caller_update/${caller.value.id}`, updatedCaller);
    if (response.status === 200) {
      console.log('Caller updated successfully.');
    } else {
      throw new Error('Failed to update caller.');
    }
  } catch (error) {
    console.error('Error updating caller: ' + (error.response?.data?.detail || error.message));
  } finally {
    settings.fetchSettings();
    showModal.value = false;
    router.push('/caller');
  }
};

const callerDelete = async () => {
  if (window.confirm("Are you sure you want to delete this caller?")) {
    showModal.value = true;
    try {
      await axios.delete(`${BACK_URL.value}/caller/caller_delete/${callerId.value}`);
      router.push(`/caller`); 
    } catch (error) {
      console.error('Error deleting caller:', error.response?.data?.detail || error.message);
    } finally {
      showModal.value = false;
    }
  }
};

const setToMakeCalls = async () => {
  caller_id.value = caller.value.id;
  caller_name.value = caller.value.name;
  caller_phone_number.value = caller.value.phone_number;
  caller_instruct.value = caller.value.caller_instruct;
  await settings.saveSettings();
  router.push('/home');
};

const toggleSmsCaller = (event) => {
  caller.value.sms_caller = event.target.checked ? 15 : 0;
};

onMounted(callerGetById);
</script>

<style scoped>
.sms-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sms-checkbox {
  margin-right: 12px;
  appearance: none;
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  /* border: 2px solid #007bff; */
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

/* 
.sms-checkbox:checked {
  background-color: #007bff;
} */

.sms-checkbox:checked::after {
  content: '\2714';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sms-checkbox-label {
  user-select: none;
  font-size: 16px;
}
</style>
