<template>
  <div class="logs-container">
    <h2>Logs & Prompts</h2>
    <div class="logs-grid">
      <div v-for="(group, index) in logGroups" :key="index" class="log-group">
        <h3>{{ group.title }}</h3>
        <div class="links-container">
          <a :href="group.logUrl" target="_blank" class="link log-link">
            <span class="link-label">Log File</span>
            <span class="link-text">{{ group.logFile }}</span>
          </a>
          <template v-if="group.promptUrls.length">
            <a v-for="(prompt, idx) in group.promptUrls" :key="idx" :href="prompt.url" target="_blank"
              class="link prompt-link">
              <span class="link-label">Prompt</span>
              <span class="link-text">{{ prompt.file }}</span>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const logGroups = [
  {
    title: 'Question Generate',
    logUrl: 'https://dev.storycall.org/logs/question_generate_log.txt',
    logFile: 'question_generate_log.txt',
    promptUrls: [
      {
        url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/question_generate_system.txt',
        file: 'question_generate_system.txt'
      },
      {
        url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/question_generate_user.txt',
        file: 'question_generate_user.txt'
      }
    ]
  },
  {
    title: 'IG Post Intro',
    logUrl: 'https://dev.storycall.org/logs/ig_post_intro_log.txt',
    logFile: 'ig_post_intro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post_intro.txt',
      file: 'ig_post_intro.txt'
    }]
  },
  {
    title: 'IG Post Intro DALLE',
    logUrl: 'https://dev.storycall.org/logs/ig_post_intro_dalle_log.txt',
    logFile: 'ig_post_intro_dalle_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post_intro_dalle.txt',
      file: 'ig_post_intro_dalle.txt'
    }]
  },
  {
    title: 'IG Post Comment Intro',
    logUrl: 'https://dev.storycall.org/logs/ig_post_comment_intro_log.txt',
    logFile: 'ig_post_comment_intro_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post_comment_intro.txt',
      file: 'ig_post_comment_intro.txt'
    }]
  },
  {
    title: 'IG Post',
    logUrl: 'https://dev.storycall.org/logs/ig_post_log.txt',
    logFile: 'ig_post_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post.txt',
      file: 'ig_post.txt'
    }]
  },
  {
    title: 'IG Post DALLE',
    logUrl: 'https://dev.storycall.org/logs/ig_post_dalle_log.txt',
    logFile: 'ig_post_dalle_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post_dalle.txt',
      file: 'ig_post_dalle.txt'
    }]
  },
  {
    title: 'IG Post Comment',
    logUrl: 'https://dev.storycall.org/logs/ig_post_comment_log.txt',
    logFile: 'ig_post_comment_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_post_comment.txt',
      file: 'ig_post_comment.txt'
    }]
  },

  {
    title: 'Question Followup',
    logUrl: 'https://dev.storycall.org/logs/question_followup_log.txt',
    logFile: 'question_followup_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/question_followup_user.txt',
      file: 'question_followup_user.txt'
    }]
  },
  {
    title: 'Instagram DM',
    logUrl: 'https://dev.storycall.org/logs/ig_dm_log.txt',
    logFile: 'ig_dm_log.txt',
    promptUrls: [{
      url: 'https://github.com/mPulseMedia/storycall_prompt/edit/main/ig_dm.txt',
      file: 'ig_dm.txt'
    }]
  },
]
</script>

<style scoped>
.logs-container {
  padding: 20px;
  color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
}

.logs-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.log-group {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
}

h2 {
  color: #ffffff;
  margin-bottom: 2rem;
}

h3 {
  color: #ffffff;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: #e7f3ff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.log-link {
  background: rgba(66, 153, 225, 0.1);
}

.prompt-link {
  background: rgba(72, 187, 120, 0.1);
}

.link:hover {
  transform: translateX(4px);
  background: rgba(255, 255, 255, 0.1);
}

.link-label {
  font-size: 0.8rem;
  opacity: 0.7;
}

.link-text {
  font-weight: 500;
}

@media (max-width: 480px) {
  .logs-container {
    padding: 1rem;
  }

  .log-group {
    padding: 0.75rem;
  }

  .link {
    padding: 0.5rem 0.75rem;
  }
}
</style>
