<template>
  <div>
    <Modal />
    <div class="grid-table-container">
      <div class="grid-table">
        <TopBar />
        <NavBar />
        <div class="nav-bar nav-bar-dark" ref="navBarRefForScroll">
          <template v-if="isEdit">
            <div class="question-nav-bar">
              <button class="nav-button" @click="previousQuestion" :disabled="currentQuestionIndex === 0">
                <i class="fas fa-arrow-left small-icon"></i>
              </button>
              <span class="question-counter">{{ currentQuestionIndex + 1 }} of {{ questionsWithoutAnswers.length
                }}</span>
              <button class="nav-button" @click="nextQuestion"
                :disabled="currentQuestionIndex === questionsWithoutAnswers.length - 1">
                <i class="fas fa-arrow-right small-icon"></i>
              </button>
            </div>
          </template>
        </div>

        <div class="grid-table-cell-wide column top-margin">
          <div>
            {{ ui_string.question_edit_title }}
          </div>
          <div v-if="caller_all" class="label-text">
            {{ settings.formatUIString(ui_string.question_parties, {
            caller_name: questionCallerName, callee_name:
              questionCalleeName
          }) }}
          </div>
          <input type="text" id="question_subject" name="question_subject" class="input-field"
            :placeholder="ui_string.question_title_default" v-model="questionSubject" @blur="handleSubjectBlur" />
        </div>
        <div class="grid-table-cell-wide column">
          <textarea :placeholder="ui_string.question_body_default" id="question_display" name="question_display"
            ref="questionDisplayRef" rows="10" style="margin-bottom: 20px" v-model="questionText"
            @blur="handleQuestionDisplayBlur"> </textarea>
        </div>

        <!-- <div class="grid-table-cell-wide column"> -->
          <!-- <div class="title-container" @click="toggleRecording">
            <span class="arrow" :class="{ 'arrow-down': showRecording }">&#9656;</span>
            <span class="centered-summary">{{ ui_string.recording_title }}</span>
          </div> -->
          <!-- <template v-if="showRecording"> -->
            <AudioRecorder :key="questionId"  :questionAudioCreate="questionAudioCreate" :updateQuestion="updateQuestion"
              :questionId="questionId" />
          <!-- </template> -->
        <!-- </div> -->

        <div class="drop-shadow"> </div>

        <div class="grid-table-cell-narrow column">
          <div class="title-container" @click="toggleFixedSchedule">
            <span class="arrow" :class="{ 'arrow-down': showFixedSchedule }">&#9656;</span>
            <span class="centered-summary">{{ ui_string.call_sched_fixed_title }}</span>
          </div>

          <template v-if="showFixedSchedule">
            <div class="schedule-container">
              <div class="schedule-row">
                <label>Date</label>
                <input type="date" v-model="fixedDate" class="selector" @change="handleDateTimeChange('date')"
                  @focus="scrollIntoView" :min="currentDate" />
              </div>
              <div class="schedule-row">
                <label>Time</label>
                <input type="time" v-model="fixedTime" class="selector" @change="handleDateTimeChange('time')"
                  @focus="scrollIntoView" />
              </div>

              <div class="schedule-row">
                <label>Repeat</label>
                <select v-model="repeatPeriod" class="selector" :disabled="!fixedDateTime" @change="handleRepeatChange">
                  <option value="none">{{ ui_string.call_repeat_none }}</option>
                  <option value="daily">{{ ui_string.call_repeat_daily }}</option>
                  <option value="weekly">{{ ui_string.call_repeat_weekly }}</option>
                </select>
              </div>
              <div class="grid-table-cell-narrow-button column">
                <div v-if="fixedDateTime" class="schedule-row">
                  <button @click="clearFixedSchedule" class="clear-button">
                    {{ ui_string.call_sched_clear }}
                  </button>
                </div>
              </div>
            </div>
          </template>

        </div>
        <div class="drop-shadow" v-if="showFixedSchedule"> </div>
        <div class="grid-table-cell-narrow column top-margin">
          <button @click="isEdit ? submitQuestionEdit(true) : questionSave()">{{ ui_string.quesiton_edit_save_button
            }}</button>
        </div>
        <div class="grid-table-cell-narrow column">
          <button @click="isEdit ? questionSaveDial() : questionSave()">{{ ui_string.call_now_button }}</button>
        </div>
        <div class="grid-table-cell-narrow column">
          <button @click="deleteQuestion(questionId)" style="color: red;">{{ ui_string.quesiton_edit_delete_button
            }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watchEffect, nextTick, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import AudioRecorder from "./AudioRecorder.vue";

const navBarRefForScroll = ref(null);

const router = useRouter();
const settings = useSettingsStore();
const { showModal, BACK_URL, FRONT_URL, caller_id, timezone, callee_phone_number, caller_phone_number, callee_id, questions, caller_all, callee_name, ui_string, question_recording, question_recording_only, isCreatingAudio, isRecording, audioUrl, audioUrls, question_audio_id } = storeToRefs(settings);
const showModalGenerateAudioId = ref(false);
const questionId = ref("");
const questionSubject = ref("");
const questionCallerName = ref("");
const questionCalleeName = ref("");
const questionCallerId = ref("");
const questionCallerPhoneNumber = ref("");
const questionText = ref("");
const questionPhoneMappings = ref([]);
const fixedScheduleId = ref(null);
const isEdit = ref(false);
const smsCallee = ref(0);
const repeatPeriod = ref('none');
const currentDate = new Date().toISOString().split('T')[0];
const fixedDate = ref("");
const fixedTime = ref("");
const questionsWithoutAnswers = ref([]);
const audioPlayerRef = ref(null);
const showRecording = ref(true);
const showFixedSchedule = ref(false);

const fixedDateTime = computed(() => {
  if (fixedDate.value && fixedTime.value) {
    const dateTimeString = `${fixedDate.value}T${fixedTime.value}:00`;
    return dateTimeString;
  }
  return null;
});

const convertToUTC = (localDateTime, timezoneOffset) => {
  if (!localDateTime) return null;
  const [datePart, timePart] = localDateTime.split('T');
  const [hour, minute] = timePart.split(':').map(Number);
  const utcHour = (hour + timezoneOffset + 24) % 24;
  return `${datePart}T${String(utcHour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00Z`;
};

const hasFixedSchedule = computed(() => Boolean(fixedDateTime.value));

const hasRepeatSchedule = computed(() => {
  return repeatPeriod.value && repeatPeriod.value !== 'none';
});

const handleRepeatChange = async () => {
  if (!hasFixedSchedule.value) {
    repeatPeriod.value = 'none';
    return;
  }

  try {
    if (hasRepeatSchedule.value) {
      await createOrUpdateRepeatSchedule();
    } else {
      await deleteRepeatSchedule();
    }
  } catch (error) {
    console.error('Error handling repeat change:', error);
    // Revert to previous state if there's an error
    repeatPeriod.value = 'none';
  }
};

const createOrUpdateRepeatSchedule = async () => {
  if (!fixedDateTime.value || !repeatPeriod.value) {
    console.warn('Missing required data for repeat schedule');
    return;
  }

  const utcRepeatTime = convertToUTC(fixedDateTime.value, timezone.value);
  
  await axios.post(`${BACK_URL.value}/schedule/repeat_schedule_update`, {
    question_id: questionId.value,
    callee_id: callee_id.value,
    caller_id: caller_id.value,
    repeat_datetime: utcRepeatTime,
    period: repeatPeriod.value
  }, {
    withCredentials: true
  });
};

const deleteRepeatSchedule = async () => {
  await axios.post(`${BACK_URL.value}/schedule/repeat_schedule_update`, {
    question_id: questionId.value,
    callee_id: callee_id.value,
    caller_id: caller_id.value,
    repeat_datetime: null,
    period: 'none'
  }, {
    withCredentials: true
  });
};

watch(() => fixedDateTime.value, async (newValue, oldValue) => {
  console.log('fixedDateTime changed:', { newValue, oldValue });
  
  if (!newValue && oldValue) {
    // Fixed schedule was cleared
    if (hasRepeatSchedule.value) {
      repeatPeriod.value = 'none';
      await deleteRepeatSchedule();
    }
  } else if (newValue && hasRepeatSchedule.value) {
    // Fixed schedule was updated and we have a repeat schedule
    await createOrUpdateRepeatSchedule();
  }
});

watch([fixedDate, fixedTime], ([newDate, newTime]) => {
  if (!newDate || !newTime) {
    console.log("Invalid or missing fixedDateTime. Schedule not created.");
  }
});

const handleDateTimeChange = async (type) => {
  setTimeout(async () => {
    if (fixedDate.value && fixedTime.value) {
      await updateScheduleFixed();
    }
  }, 100);
};

const toggleSmsCallee = (event) => {
  smsCallee.value = event.target.checked ? 15 : 0;
};

const updateScheduleFixed = async () => {
  if (fixedDateTime.value) {
    try {
      const utcCallTime = convertToUTC(fixedDateTime.value, timezone.value);

      const payload = {
        question_id: questionId.value,
        callee: callee_id.value,
        caller: caller_id.value,
        call_time: utcCallTime,
        timezone: timezone.value
      };

      const response = await axios.post(`${BACK_URL.value}/schedule/schedule_fixed_create`, payload);

      if (response.data.message) {
        console.log("Fixed schedule created successfully");
      }
    } catch (error) {
      console.error("Failed to create fixed schedule:", error);
    }
  } else {
    console.warn("Invalid or missing fixedDateTime. Schedule not created.");
  }
};

watch(fixedDate, (newDate) => {
  if (!newDate) {
    fixedTime.value = "";
  }
});

watch(fixedTime, (newTime) => {
  if (!newTime) {
    fixedDate.value = "";
  }
});

watch(fixedDateTime, (newDateTime) => {
  settings.fixed_date_time = newDateTime;
});

const handleQuestionDisplayBlur = async () => {
  if (!isEdit.value && !questionSubject.value.trim()) {
    await questionSave();
  } else if (isEdit.value) {
    await submitQuestionEdit(false);
  }
};

const handleSubjectBlur = async () => {
  if (!isEdit.value && questionSubject.value.trim()) {
    await questionSave();
  } else if (isEdit.value) {
    await submitQuestionEdit(false);
  }
};

const questionAudioCreate = async () => {
  isCreatingAudio.value = true;
  showModalGenerateAudioId.value = true;
  try {
    const response = await generateAudio({ text: questionText.value, caller_id: caller_id.value });
    if (response.ok) {
      const data = await response.json();
      question_audio_id.value = data.question_audio_id;

      const isAudioReady = await waitForAudioFile(data.question_audio_id);

      if (isAudioReady) {
        audioUrl.value = `${FRONT_URL.value}/question_audio/${data.question_audio_id}.mp3`;
        const audioType = question_recording.value
          ? "merged"
          : question_recording_only.value
            ? "recorded"
            : "default";

        settings.setAudioUrl(questionId.value, audioType, audioUrl.value);
        if (!question_recording.value && !question_recording_only.value) {
          settings.setAudioUrl(questionId.value, 'generated', audioUrl.value);
        }

        question_audio_id.value = data.question_audio_id;

        showModalGenerateAudioId.value = false;

        if (isEdit.value) {
          await updateQuestion();
        }
        return data.question_audio_id;
      } else {
        throw new Error("Audio file not ready after multiple attempts");
      }
    } else {
      throw new Error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Error generating audio:", error);
    showModalGenerateAudioId.value = false;
  } finally {
    isCreatingAudio.value = false;
  }
};

const generateAudio = async ({ text, caller_id }) => {
  const response = await fetch(`${BACK_URL.value}/ai/question_audio_create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: text,
      similarity_boost: 0.2,
      stability: 0.2,
      style: 0.7,
      caller_id: caller_id,
      question_recording: question_recording.value,
      question_recording_only: question_recording_only.value
    }),
  });
  return response;
};

const updateQuestion = async () => {
  try {
    const response = await axios.post(`${BACK_URL.value}/question/question_update`, null, {
      params: {
        question_id: questionId.value,
        question_subject: questionSubject.value,
        question_text: questionText.value,
        question_audio_id: question_audio_id.value,
        sms_callee: smsCallee.value,
      },
    });
    return response;
  } catch (error) {
    console.error(`Failed to update question: ${error}`);
    throw error;
  }
};

const questionSaveDial = async () => {
  try {
    const response = await updateQuestion();
    if (response.status === 200) {
      navToDial(questionId.value);
    } else {
      throw new Error(`Failed to save and dial question: HTTP status ${response.status}`);
    }
  } catch (error) {
    console.error(`Failed to save and dial question: ${error}`);
  }
};

const submitQuestionEdit = async (redirect = false) => {
  try {
    await updateQuestion();
    if (redirect) {
      router.push("/question_list");
    }
  } catch (error) {
    console.error(`Failed to update question: ${error}`);
  }
};

const questionSave = async () => {
  showModal.value = true;
  try {
    if (!questionSubject.value.trim()) {
      questionSubject.value = ui_string.value.question_subject_default;
    }

    if (!audioUrl.value) {
      const audioId = await questionAudioCreate();
      if (!audioId) {
        throw new Error("Failed to generate audio");
      }
    }

    const response = await axios.post(`${BACK_URL.value}/question/question_save`, {
      question_audio_id: question_audio_id.value,
      question_subject: questionSubject.value,
      question_text: questionText.value,
      question_callee: callee_id.value,
      question_caller: caller_id.value,
      question_nudge: "N/A",
      sms_callee: smsCallee.value,
    });

    if (response.status === 200) {
      const data = response.data;
      questionId.value = data.id;
      isEdit.value = true;

      if (fixedDate.value && fixedTime.value) {
        await updateScheduleFixed();
      }

      if (repeatPeriod.value !== 'none') {
        await repeatScheduleUpdate();
      }

      router.push(`/question_edit/${data.id}`);
    } else {
      throw new Error("HTTP-Error: " + response.status);
    }
  } catch (error) {
    console.error("Failed to save question:", error);
    alert("Failed to save question. Please try again.");
  } finally {
    showModal.value = false;
  }
};

const deleteQuestion = async (id) => {
  if (window.confirm(ui_string.value.quesiton_delete_dialog)) {
    try {
      await axios.delete(`${BACK_URL.value}/question/question_delete/${id}`);
      router.push("/question_list");
    } catch (error) {
      if (error.response) {
        console.error(`HTTP error! status: ${error.response.status}`);
      } else {
        console.error(`An error occurred: ${error}`);
      }
    }
  }
};

const navToDial = (id) => {
  router.push(`/dial/${id}`);
};

const fetchSchedule = () => {
  const schedules = questions.value;
  const matchingSchedule = schedules.find(schedule =>
    schedule.id === parseInt(questionId.value) && schedule.schedule_type === "fixed"
  );

  if (matchingSchedule) {
    const utcCallTime = new Date(matchingSchedule.schedule_call_time);

    const userTimezoneOffset = timezone.value ;
    const localHour = (utcCallTime.getUTCHours() - userTimezoneOffset + 24) % 24;
    const localDate = new Date(utcCallTime);
    localDate.setUTCHours(localHour);

    fixedDate.value = localDate.toISOString().split('T')[0];
    fixedTime.value = `${String(localHour).padStart(2, '0')}:${String(utcCallTime.getUTCMinutes()).padStart(2, '0')}`;
    fixedScheduleId.value = matchingSchedule.id;
  }
};

const scrollIntoView = (event) => {
  // setTimeout(() => {
  //   event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  // }, 100); 
};

const clearFixedSchedule = async () => {
  try {
    // First clear the repeat schedule if it exists
    if (hasRepeatSchedule.value) {
      await deleteRepeatSchedule();
      repeatPeriod.value = 'none';
    }

    // Then clear the fixed schedule
    fixedDate.value = "";
    fixedTime.value = "";
    
    if (fixedScheduleId.value) {
      await axios.delete(`${BACK_URL.value}/schedule/schedule_fixed_delete/${fixedScheduleId.value}`);
      fixedScheduleId.value = null;
    }
  } catch (error) {
    console.error("Failed to clear schedule:", error);
  }
};


const currentQuestionIndex = computed(() => {
  const index = questionsWithoutAnswers.value.indexOf(parseInt(questionId.value));
  return index;
});

const fetchQuestionDetails = async (id) => {
  if (id) {
    isEdit.value = true;
    fixedDate.value = "";
    fixedTime.value = "";
    await fetchQuestionsWithoutAnswers();
    try {
      const response = await axios.get(`${BACK_URL.value}/question/question_get_byid/${id}`);
      if (response.data) {
        questionId.value = response.data.id.toString();
        questionSubject.value = response.data.question_subject;
        questionText.value = response.data.question_text;
        questionCallerId.value = response.data.caller_id;
        questionCallerName.value = response.data.caller_name;
        questionCalleeName.value = response.data.callee_name;
        questionCallerPhoneNumber.value = response.data.caller_phone_number;
        question_audio_id.value = response.data.question_audio_id;
        audioUrl.value = `${FRONT_URL.value}/question_audio/${response.data.question_audio_id}.mp3`;
        questionPhoneMappings.value = response.data.question_phone_mappings;
        smsCallee.value = response.data.sms_callee || 0;
        repeatPeriod.value = response.data.repeat_period || 'none';
        settings.setAudioUrl(questionId.value, "default", audioUrl.value);
      }
      await fetchSchedule();
      // await updateScheduleFixed();
    } catch (error) {
      console.error(`Failed to fetch question with id ${id}: ${error}`);
    }
  }
};

const clearRecordingState = () => {
  question_recording.value = null;
  question_recording_only.value = false;
  if (audioUrl.value && audioUrl.value.startsWith('blob:')) {
    URL.revokeObjectURL(audioUrl.value);
  }
  audioUrl.value = null;
};

const previousQuestion = async () => {
  if (currentQuestionIndex.value > 0) {
    clearRecordingState();
    const previousIndex = currentQuestionIndex.value - 1;
    const previousId = questionsWithoutAnswers.value[previousIndex];
    await router.push(`/question_edit/${previousId}`);
    await fetchQuestionDetails(previousId);
    questionId.value = previousId.toString();
    window.location.reload();
  }
};

const nextQuestion = async () => {
  if (currentQuestionIndex.value < questionsWithoutAnswers.value.length - 1) {
    clearRecordingState();
    const nextIndex = currentQuestionIndex.value + 1;
    const nextId = questionsWithoutAnswers.value[nextIndex];
    await router.push(`/question_edit/${nextId}`);
    await fetchQuestionDetails(nextId);
    questionId.value = nextId.toString();
    window.location.reload();
  }
};

const fetchQuestionsWithoutAnswers = () => {
  questionsWithoutAnswers.value = questions.value.map(question => question.id);
  console.log("Questions without answers:", questionsWithoutAnswers.value);
};

const waitForAudioFile = async (audioId, maxAttempts = 15, interval = 1000) => {
  const audioUrl = `${FRONT_URL.value}/question_audio/${audioId}.mp3`;

  for (let i = 0; i < maxAttempts; i++) {
    try {
      const response = await fetch(audioUrl, { method: 'HEAD' });
      if (response.ok && response.headers.get('Content-Length') > 0) {
        console.log("Audio file is ready and accessible");
        return true;
      }
    } catch (error) {
      console.log(`Attempt ${i + 1}: Audio file not ready yet`);
    }
    await new Promise(resolve => setTimeout(resolve, interval));
  }

  console.error('Audio file creation or access timed out');
  return false;
};


const updateAudioId = (audioId) => {
  question_audio_id.value = audioId;
  audioUrl.value = `${FRONT_URL.value}/question_audio/${audioId}.mp3`;
};

const toggleRecording = () => {
  showRecording.value = !showRecording.value;
};

const toggleFixedSchedule = () => {
  showFixedSchedule.value = !showFixedSchedule.value;
};

onMounted(async () => {
  await settings.fetchSettings();
  const id = router.currentRoute.value.params.id;
  await fetchQuestionDetails(id);
  if (id) {
    fetchQuestionsWithoutAnswers();
  }
  if (!id) {
    isEdit.value = false;
    questionId.value = "";
    questionSubject.value = "";
    questionText.value = "";
    question_audio_id.value = "";
    audioUrl.value = "";
    questionPhoneMappings.value = [];
  }
  // Scroll to the top of the div with the arrows
  const navBarElement = navBarRefForScroll.value;
  if (navBarElement) {
    navBarElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  
});


onUnmounted(() => {
  clearRecordingState();
});

</script>

<style scoped>
@import "../assets/question_edit.css";
</style>
