<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div class="bottom-margin"></div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Name" v-model="newCaller.name" />
      </div>
      <div class="grid-table-cell-wide row">
        <div style="display: flex; justify-content: space-between; width: 100%">
          +1 &nbsp;
          <input type="tel" placeholder="212" v-model="newCaller.area_code" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="555" v-model="newCaller.prefix" maxlength="3"
            style="width: 20%; margin-right: 2%; text-align: center" title="Only numeric values are allowed." />
          <input type="tel" placeholder="1234" v-model="newCaller.suffix" maxlength="4"
            style="width: 38%; text-align: center" title="Only numeric values are allowed." />
        </div>
      </div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="Instagram Username" v-model="newCaller.ig_username" />
      </div>
      <div class="grid-table-cell-wide column">
        <input type="text" placeholder="EL Voice ID" v-model="newCaller.el_voice_id" />
      </div>

      <div class="grid-table-cell-wide column">
        Caller Instructions
        <textarea placeholder="Caller Instructions" v-model="newCaller.caller_instruct"   rows="5"></textarea>
      </div>

      <div class="grid-table-cell-narrow column">
        <button @click="callerCreate">Save</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, family_id, BACK_URL } = storeToRefs(settings);
const newCaller = ref({
  name: '',
  area_code: '',
  prefix: '',
  suffix: '',
  el_voice_id: '9RpXYdocFG8u7K3pqNxi',
  family_id: '',
  caller_instruct: '',
  ig_username: ''  //
});

// Fetch settings on component mount
onMounted(async () => {
  await settings.fetchSettings();
  newCaller.value.family_id = family_id.value;
});

const callerCreate = async () => {
  showModal.value = true;
  const areaCodeValid = /^\d{3}$/.test(newCaller.value.area_code);
  const prefixValid = /^\d{3}$/.test(newCaller.value.prefix);
  const suffixValid = /^\d{4}$/.test(newCaller.value.suffix);
  if (!areaCodeValid || !prefixValid || !suffixValid) {
    alert("Please ensure the area code and prefix have exactly 3 digits, and the suffix has exactly 4 digits, all numeric.");
    showModal.value = false;
    return;
  }
  newCaller.value.phone_number = `+1${newCaller.value.area_code}${newCaller.value.prefix}${newCaller.value.suffix}`;
  try {
    const response = await axios.post(`${BACK_URL.value}/caller/caller_create`, newCaller.value);
    router.push(`/caller`);
  } catch (error) {
    console.error("Failed to create caller:", error.response?.data?.detail || error.message);
    alert(`Failed to create caller: ${error.response?.data?.detail || error.message}`);
  } finally {
    showModal.value = false;
  }
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
