<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <ConfigNavBar />
      <div class="nav-bar bottom-margin"></div>
      <div class="grid-table-cell-narrow column bottom-margin">{{ ui_string.caller_list_title }}</div>
      <template v-for="(caller, index) in callers" :key="caller.id" class="caller-item">
        <div @click="setToMakeCalls(caller)" class="grid-table-cell-wide row inlayed inlayed-top row-no-buttons">
          <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
            <span> {{ caller.name }}</span>
            <div 
              @click.stop="navToCallerUpdate(caller.id)" 
              style="cursor: pointer; padding: 10px 15px; margin: -10px -5px -10px 0;" 
              class="three-dots"
            > 
              ••• 
            </div>
          </div>
        </div>
      </template>
      <div @click="setEveryoneCaller" class="grid-table-cell-wide row inlayed inlayed-top row-no-buttons">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <span>{{ ui_string.caller_everyone }}</span>
        </div>
      </div>
      <div style="margin-bottom: 15px"></div>
      <div class="grid-table-cell-narrow column">
        <button @click="navigateToCallerCreate">{{ ui_string.caller_new_button }}</button>
      </div>
      <div class="bottom-margin"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { storeToRefs } from 'pinia';
import ConfigNavBar from "./ConfigNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";

const settings = useSettingsStore();
const { showModal, BACK_URL, caller_id, caller_name, caller_phone_number, caller_instruct, caller_all, ui_string } = storeToRefs(settings);
const router = useRouter();
let callers = ref([]);

const navigateToCallerCreate = () => {
  router.push('/caller_create');
};

const callerGetAll = async () => {
  showModal.value = true;
  try {
    callers.value = await settings.fetchCallers();
  } finally {
    showModal.value = false;
  }
};

const navToCallerUpdate = (caller_id) => {
  router.push(`/caller_update/${caller_id}`);
};

const setToMakeCalls = async (caller) => {
  await settings.setActiveCaller(caller);
  router.push('/question_list');
};

const setEveryoneCaller = async () => {
  caller_all.value = true;
  await settings.saveSettings();
  router.push('/question_list');
};

onMounted(callerGetAll);
</script>
