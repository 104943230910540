<template>
  <Modal />
  <!-- <div class="dev-buttons">
    <div><button @click="clearData" class="dev-button">Clear Data (Dev)</button></div>
    <div><button @click="fillTestData" class="dev-button">Fill Test Data (Dev)</button></div>
  </div> -->
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <div class="grid-table-cell-wide column">
        <div class="container">
          <img src="../assets/follow-granny-logo2.png" alt="Follow Granny Logo" class="logo" />
          <h2 class="signup-title">{{ ui_string.family_ig_title }}</h2>
          <p class="signup-step">{{ ui_string.family_ig_step1 }}</p>

          <!-- <input type="text" :placeholder="formatUIString(ui_string.family_ig_callee_ig, { ig_account: signupData.family_slug || ui_string.family_ig_callee_ig_backup })" v-model="signupData.family_slug" /> -->

          <!-- <div v-if="signupData.ig_username" class="connected-account">
            Connected Instagram: <strong>{{ signupData.ig_username }}</strong>
          </div> -->
          <div v-if="igAuthStatus === 'failed'" class="error-message">
            {{ formatUIString(ui_string.family_ig_auth_status_failed, {
              ig_account: signupData.family_slug ||
                ui_string.family_ig_callee_ig_backup }) }}
          </div>


          <button @click="handleAllowConnection" class="ig-auth-button" :disabled="signupData.ig_verified">
            {{ signupData.ig_verified ? `${signupData.family_slug} Connected ✓` : ui_string.family_ig_connect }}
          </button>

          <p class="signup-step">{{ formatUIString(ui_string.family_ig_step2, {
            granny_account: signupData.family_slug
              || ui_string.family_ig_callee_ig_backup }) }}</p>
          <p class="signup-step">{{ ui_string.family_ig_step4 }}</p>


          <input type="text" :placeholder="ui_string.family_ig_callee_phone" v-model="signupData.callee_phone" />

          <p class="signup-step">{{ formatUIString(ui_string.family_ig_step5, {
            ig_account: signupData.family_slug ||
              ui_string.family_ig_callee_ig_backup }) }}</p>
          <div v-for="(_, index) in 3" :key="index">
            <input type="text" :placeholder="ui_string.family_ig_guest1" v-model="signupData.ig_caller[index]" />
          </div>

          <p class="signup-step">{{ formatUIString(ui_string.family_ig_step7, {
            granny_account: signupData.family_slug
              || ui_string.family_ig_callee_ig_backup }) }}</p>

          <input type="text"
            :placeholder="formatUIString(ui_string.family_ig_callee_password, { granny_account: signupData.family_slug || ui_string.family_ig_callee_ig_backup })"
            v-model="signupData.family_password" />

          <button class="login-button" @click="handleLoginToStoryCall" :disabled="!signupData.ig_verified">
            {{ ui_string.family_ig_login }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue';
import Modal from './Modal.vue';
import TopBar from './TopBar.vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

const settings = useSettingsStore();
const { BACK_URL, ui_string } = storeToRefs(settings);
const { formatUIString } = settings;

// Create initial state object
const initialSignupState = {
  family_slug: '',
  family_password: '',
  ig_verified: false,
  ig_username: '',
  callee_phone: '',
  family_timezone: 5,
  caller_phone: '',
  caller_name: '',
  preferred_time: '16:00',
  ig_caller: ['', '', '']
};

// Use the initial state object for the ref
const signupData = ref({ ...initialSignupState });

const authUrl = ref('');
const loading = ref(false);

const route = useRoute();
const igAuthStatus = ref(route.query.ig_auth_status || null);

const router = useRouter();
const showModal = ref(false);
const timezone = ref('America/New_York');

// Update the watch effect to handle both success and failed states
watch(igAuthStatus, (newStatus) => {
  if (newStatus === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
    signupData.value.family_slug = route.query.ig_username || '';
  } else if (newStatus === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
});

const getAuthUrl = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/ig/instagram/auth_url`, {
      params: { state: 'ig_signup' }
    });
    authUrl.value = response.data.auth_url;
  } catch (error) {
    console.error('Error fetching Instagram auth URL:', error);
  }
};

const handleAllowConnection = async () => {
  loading.value = true;
  await getAuthUrl();

  if (authUrl.value) {
    // Save current signup data before redirecting
    localStorage.setItem('signupData', JSON.stringify(signupData.value));
    window.location.href = authUrl.value;
  }
  loading.value = false;
};

// Also update the onMounted check
onMounted(async () => {
  // Check if all required cookies are present
  const requiredCookies = [
    'caller_phone_number',
    'caller_name',
    'caller_id',
    'callee_name',
    'callee_phone_number',
    'callee_id',
    'family_id',
    'family_slug'
  ];

  const allCookiesPresent = requiredCookies.every(cookieName => {
    return document.cookie.split('; ').some(cookie => cookie.startsWith(`${cookieName}=`));
  });

  if (allCookiesPresent) {
    console.log('All required cookies are present. Redirecting to home.');
    router.push('/');
    return;
  }

  const storedData = localStorage.getItem('signupData');
  if (storedData) {
    signupData.value = JSON.parse(storedData);
  }
  if (route.query.ig_auth_status === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
    signupData.value.family_slug = route.query.ig_username || '';
  } else if (route.query.ig_auth_status === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
  await getAuthUrl();
});

// Save data when it changes
watch(signupData, (newData) => {
  localStorage.setItem('signupData', JSON.stringify(newData));
}, { deep: true });

const validatePhoneNumber = (phone) => {
  // Validate format +1XXXXXXXXXX (exactly 12 characters)
  const phoneRegex = /^\+1\d{10}$/;
  return phoneRegex.test(phone);
};

const handleLoginToStoryCall = async () => {
  if (!signupData.value.ig_verified) {
    alert('Please complete Instagram connection first.');
    return;
  }

  // Add phone validation
  if (!validatePhoneNumber(signupData.value.callee_phone)) {
    alert(ui_string.value.phone_format_error);
    return;
  }

  showModal.value = true;
  try {
    // Create family with whitelist
    const familyResponse = await axios.post(`${BACK_URL.value}/family/family_create`, {
      name: signupData.value.family_slug,
      family_slug: signupData.value.family_slug,
      password: signupData.value.family_password,
      family_instruct: '',
    });

    const familyId = familyResponse.data.id;

    const callerIds = []; // Array to store caller IDs

    // Create additional callers for each ig_caller
    for (const igUsername of signupData.value.ig_caller) {
      if (igUsername && igUsername.trim()) {
        const callerResponse = await axios.post(`${BACK_URL.value}/caller/caller_create`, {
          name: igUsername,
          phone_number: '+18335761437',
          family_id: familyId,
          el_voice_id: "9RpXYdocFG8u7K3pqNxi",
          caller_instruct: '',
          ig_username: igUsername,
          default_caller: true
        });

        callerIds.push(callerResponse.data.id); // Store the caller ID

        // Set the first caller's ID and name in signupData
        if (!signupData.value.caller_name) {
          signupData.value.caller_name = igUsername;
          signupData.value.caller_phone = '+18335761437';
          signupData.value.caller_id = callerResponse.data.id;
        }
      }
    }

    // Create callee using the first caller's ID from signupData
    const calleeResponse = await axios.post(`${BACK_URL.value}/callee/callee_create`, {
      callee_name: signupData.value.family_slug,
      phone_number: signupData.value.callee_phone,
      family_id: familyId,
      caller_id: signupData.value.caller_id,
      background_text: '',
      callee_instruct: '',
      ig_username: signupData.value.family_slug
    });

    // Create schedule for each caller
    for (const callerId of callerIds) {
      await axios.post(`${BACK_URL.value}/schedule/schedule_slot_schedule_update`, {
        callee_id: calleeResponse.data.id,
        caller_id: callerId, // Use the specific caller ID
        time: signupData.value.preferred_time,
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true
      });
    }

    // Update family settings (now directly in Family table)
    await axios.put(`${BACK_URL.value}/family/family_update/${familyId}`, {
      timezone: signupData.value.family_timezone,
      family_instruct: '',
    });

    // Set cookies for automatic login
    const expires = "expires=Fri, 31 Dec 9999 23:59:59 GMT";
    document.cookie = `family_slug=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `family_id=${familyId};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_id=${signupData.value.caller_id};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_name=${signupData.value.caller_name};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `caller_phone_number=${signupData.value.caller_phone};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_id=${calleeResponse.data.id};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_name=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_phone_number=${signupData.value.callee_phone};path=/;domain=.storycall.org;${expires}`;

    // Create initial Instagram post
    try {
      await axios.post(`${BACK_URL.value}/ig/post_initial_create`, {
        callee_id: calleeResponse.data.id,
        ig_callers: signupData.value.ig_caller.filter(caller => caller.trim())
      });
    } catch (igError) {
      console.error('Failed to create initial Instagram post:', igError);
    }


    // Clear stored signup data
    localStorage.removeItem('signupData');

    // Redirect to home instead of family_join since we're already logged in
    router.push('/');
  } catch (error) {
    console.error('Failed to complete signup:', error);
    alert('Failed to complete signup. Please try again.');
  } finally {
    showModal.value = false;
  }
};





const fillTestData = () => {
  signupData.value = {
    ...initialSignupState,
    family_slug: 'oakleafann38',
    family_password: 'aiforgood',
    callee_phone: '+18018083373',
    preferred_time: '18:00',
    family_timezone: 6, // Central Time
    caller_name: 'kid-212',
    caller_phone: '+12125187855',
    ig_white_list: ['wh1', 'wh2', ''],
    ig_verified: true,
    caller_verified: true,
    ig_username: 'oakleafann38'
  };
};

const clearData = () => {
  localStorage.removeItem('signupData');
  signupData.value = { ...initialSignupState };
};

</script>

<style scoped>
@import '../assets/ig_signup.css';

.ig-auth-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.signup-title {
  font-size: 16px;
}

.status-icon {
  font-size: 1.5em;
}

.status-icon.success {
  color: #4CAF50;
}

.status-icon.failed {
  color: #f44336;
}

.info,
.error {
  padding: 15px;
  margin: 10px 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
  color: #3b3b3b;
}

.error {
  border: 1px solid red;
}

.verification-code {
  display: block;
  font-size: 1.5em;
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.connected-account {
  margin: 15px 0;
  color: #3b3b3b;
  border-radius: 4px;
  font-size: 16px;
}

.logo {
  max-width: 200px;
  margin: 0 auto 20px;
  display: block;
  max-height: 100px;
  border-radius: 50%;
}
</style>
