<template>
    <Modal />
    <div class="grid-table-container">
        <div class="grid-table">
            <TopBar />
            <!-- <FamilyNavBar /> -->
            <div class="grid-table-cell-wide column" style="margin-top: 20px;">
                <div class="label-text">{{ ui_string.family_section_title }}</div>
            </div>
            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.family__create_name_default" v-model="newFamily.name" />
            </div>
            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.family_name_nospace_default"
                    v-model="newFamily.family_slug" />
            </div>
            <div class="grid-table-cell-wide column">
                <input type="password" :placeholder="ui_string.family_password_default" v-model="newFamily.password" />
            </div>


            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ ui_string.family_timezone_title }}</div>
                <select v-model="timezone">
                    <option value="5" selected>Eastern (New York)</option>
                    <option value="6">Central (Chicago)</option>
                    <option value="7">Mountain (Denver)</option>
                    <option value="8">Pacific (San Francisco)</option>
                </select>
            </div>


            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ ui_string.family_instruct_title }}</div>
                <textarea :placeholder="ui_string.family_instruct_default" rows="5" v-model="familyInstruct"></textarea>
            </div>
            <div class="drop-shadow"> </div>

            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ ui_string.caller_section_title }}</div>
            </div>
            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.caller_name_default" v-model="newCaller.name" />
            </div>

            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.caller_ig_username_default"
                    v-model="newCaller.ig_username" />
            </div>

            <div class="grid-table-cell-wide row">
                <div style="display: flex; justify-content: space-between; width: 100%">
                    +1 &nbsp;
                    <input type="tel" :placeholder="ui_string.caller_phone_area" v-model="newCaller.area_code"
                        maxlength="3" style="width: 20%; margin-right: 2%; text-align: center"
                        title="Only numeric values are allowed." />
                    <input type="tel" :placeholder="ui_string.caller_phone_prefix" v-model="newCaller.prefix"
                        maxlength="3" style="width: 20%; margin-right: 2%; text-align: center"
                        title="Only numeric values are allowed." />
                    <input type="tel" :placeholder="ui_string.caller_phone_line" v-model="newCaller.suffix"
                        maxlength="4" style="width: 38%; text-align: center" title="Only numeric values are allowed." />
                </div>
            </div>
            <!-- <div class="grid-table-cell-wide column">
                <input type="text" placeholder="EL Voice ID" v-model="newCaller.el_voice_id" />
            </div> -->

            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ callerInstructTitle }}</div>
                <textarea :placeholder="ui_string.caller_instruct_default" rows="5"
                    v-model="newCaller.caller_instruct"></textarea>
            </div>
            <div class="drop-shadow"> </div>

            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ ui_string.callee_section_title }}</div>
            </div>
            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.callee_name_default" v-model="newCallee.callee_name" />
            </div>
            <div class="grid-table-cell-wide column">
                <input type="text" :placeholder="ui_string.callee_ig_username_default"
                    v-model="newCallee.ig_username" />
            </div>
            <div class="grid-table-cell-wide row">
                <div style="display: flex; justify-content: space-between; width: 100%">
                    +1 &nbsp;
                    <input type="tel" :placeholder="ui_string.callee_phone_area" v-model="newCallee.area_code"
                        maxlength="3" style="width: 20%; margin-right: 2%; text-align: center"
                        title="Only numeric values are allowed." />
                    <input type="tel" :placeholder="ui_string.callee_phone_prefix" v-model="newCallee.prefix"
                        maxlength="3" style="width: 20%; margin-right: 2%; text-align: center"
                        title="Only numeric values are allowed." />
                    <input type="tel" :placeholder="ui_string.callee_phone_line" v-model="newCallee.suffix"
                        maxlength="4" style="width: 38%; text-align: center" title="Only numeric values are allowed." />
                </div>
            </div>

            <div class="grid-table-cell-wide column">
                <div class="label-text">{{ calleeInstructTitle }}</div>

            </div>

            <div class="grid-table-cell-wide column">
                <textarea :placeholder="ui_string.callee_instruct_default" rows="5"
                    v-model="newCallee.callee_instruct"></textarea>
            </div>
            <div class="grid-table-cell-narrow column">
                <button @click="createFamily">{{ ui_string.family_create_button }}</button>
                <button @click="generateRandomData">Generate Random Data</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import FamilyNavBar from "./FamilyNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const router = useRouter();
const settings = useSettingsStore();
const { showModal, BACK_URL, ui_string } = storeToRefs(settings);

const { formatUIString } = settings;

const timezone = ref("5");

const newFamily = ref({
    name: "",
    family_slug: "",
    password: "",
});

const newCaller = ref({
    name: "",
    area_code: "",
    prefix: "",
    suffix: "",
    el_voice_id: "9RpXYdocFG8u7K3pqNxi",
    caller_instruct: "",
    ig_username: "",
});

const newCallee = ref({
    callee_name: "",
    area_code: "",
    prefix: "",
    suffix: "",
    background_text: "",
    callee_instruct: "",
    ig_username: "",
});

const familyId = ref(null);
const familyInstruct = ref("");

// Create computed properties for the formatted strings
const callerInstructTitle = computed(() => {
    if (ui_string.value && ui_string.value.caller_instruct_title) {
        return formatUIString(ui_string.value.caller_instruct_title, {
            caller_name: newCaller.value.name
        });
    }
    return '';
});

const calleeInstructTitle = computed(() => {
    if (ui_string.value && ui_string.value.callee_instruct_title) {
        return formatUIString(ui_string.value.callee_instruct_title, {
            callee_name: newCallee.value.callee_name
        });
    }
    return '';
});

const createFamily = async () => {
    if (!newFamily.value.name || !newFamily.value.family_slug || !newFamily.value.password) {
        alert("Name, Family Slug, and Password are required.");
        return;
    }

    // Use caller information for callee if callee information is not provided
    if (!newCallee.value.callee_name && !newCallee.value.area_code && !newCallee.value.prefix && !newCallee.value.suffix) {
        newCallee.value.callee_name = newCaller.value.name;
        newCallee.value.area_code = newCaller.value.area_code;
        newCallee.value.prefix = newCaller.value.prefix;
        newCallee.value.suffix = newCaller.value.suffix;
        newCallee.value.callee_instruct = newCaller.value.caller_instruct;
    }

    showModal.value = true;
    try {
        newFamily.value.family_instruct = familyInstruct.value;
        newFamily.value.timezone = timezone.value;
        const response = await axios.post(`${BACK_URL.value}/family/family_create`, newFamily.value);
        if (response.status === 200) {
            familyId.value = response.data.id;
            newCaller.value.family_id = familyId.value;
            newCallee.value.family_id = familyId.value;
            await createCaller(familyId.value);
            router.push('/family_join');
        } else {
            throw new Error("Failed to create family.");
        }
    } catch (error) {
        console.error("Failed to create family:", error);
        alert("Failed to create family. Please check your input.");
    } finally {
        showModal.value = false;
    }
};

const createCaller = async (familyId) => {
    try {
        newCaller.value.phone_number = `+1${newCaller.value.area_code}${newCaller.value.prefix}${newCaller.value.suffix}`;
        newCaller.value.family_id = familyId;
        console.debug("Creating caller with data:", newCaller.value);
        const response = await axios.post(`${BACK_URL.value}/caller/caller_create`, newCaller.value);
        if (response.status === 201) {
            console.log("Caller created successfully.");
            const callerId = response.data.id;
            await updateSettings(callerId, newCaller.value.name, timezone.value);
            await createCallee(familyId, callerId);
        } else {
            throw new Error("Failed to create caller.");
        }
    } catch (error) {
        console.error("Failed to create caller:", error);
        alert("Failed to create caller. Please check your input.");
    }
};

const createCallee = async (familyId, callerId) => {
    try {
        newCallee.value.phone_number = `+1${newCallee.value.area_code}${newCallee.value.prefix}${newCallee.value.suffix}`;
        newCallee.value.family_id = familyId;
        newCallee.value.caller_id = callerId;
        const response = await axios.post(`${BACK_URL.value}/callee/callee_create`, newCallee.value);
        if (response.status === 200) {
            console.log("Callee created successfully.");
        } else {
            throw new Error("Failed to create callee.");
        }
    } catch (error) {
        console.error("Failed to create callee:", error);
        alert("Failed to create callee. Please check your input.");
    }
};

const updateSettings = async (callerId, callerName, timezone) => {
    try {
        const updateData = {
            timezone: timezone,
            family_instruct: familyInstruct.value
        };
        await axios.put(`${BACK_URL.value}/family/family_update/${familyId.value}`, updateData);
        console.log("Family updated successfully.");
    } catch (error) {
        console.error("Failed to update family:", error);
        alert("Failed to update family. Please check your input.");
    }
};



const generateRandomData = () => {
    const randomString = Math.random().toString(36).substring(2, 7);
    const randomNumber = (length) => Math.floor(Math.random() * Math.pow(10, length)).toString().padStart(length, '0');

    newFamily.value.name = `Family ${randomString}`;
    newFamily.value.family_slug = `family_${randomString}`;
    newFamily.value.password = 'aiforgood';

    newCaller.value.name = `Caller ${randomString}`;
    newCaller.value.area_code = randomNumber(3);
    newCaller.value.prefix = randomNumber(3);
    newCaller.value.suffix = randomNumber(4);
    newCaller.value.el_voice_id = randomString;
    newCaller.value.caller_instruct = `Caller Instruction ${randomString}`;

    newCallee.value.callee_name = `Callee ${randomString}`;
    newCallee.value.area_code = randomNumber(3);
    newCallee.value.prefix = randomNumber(3);
    newCallee.value.suffix = randomNumber(4);
    newCallee.value.background_text = `Background ${randomString}`;
    newCallee.value.callee_instruct = `Callee Instruction ${randomString}`;

    familyInstruct.value = `Instruction ${randomString}`;
};


onMounted(async () => {
    try {
        await settings.fetchUIStrings();
    } catch (error) {
        console.error('Error fetching UI strings:', error);
    }
    timezone.value = settings.timezone || "5";
    window.scrollTo(0, 0);
});

// Expose formatUIString to the template
// defineExpose({ formatUIString });
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {}
</style>
