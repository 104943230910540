<template>
  <div class="nav-bar">
    <div class="nav-bar-top">
      <div class="form-group">
        <input type="text" :placeholder="ui_string.family_name_default" v-model="loginData.family_slug" />
      </div>
      <div class="form-group">
        <input type="password" :placeholder="ui_string.family_password_default" v-model="loginData.password" @keyup.enter="submitLogin" />
      </div>
      <div class="form-group">

        <button @click="submitLogin" >{{ ui_string.family_join_button }}</button>
      </div>
      <div v-if="loginStatus !== null" class="form-group">
        <p :class="{ 'success-message': loginStatus, 'error-message': !loginStatus }">
          {{ loginStatus ? 'Login successful!' : 'Login failed. Please check your credentials.' }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useSettingsStore } from "../store";
import axios from "axios";
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const router = useRouter();
const route = useRoute();
const { showModal, BACK_URL, ui_string } = storeToRefs(settings);

const isFamilyJoin = computed(() => route.path === "/family_join");
const isFamilyCreate = computed(() => route.path === "/family_create");

const loginData = ref({
  family_slug: 'adams',
  password: 'aiforgood'
});
const loginStatus = ref(null);

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const submitLogin = async () => {
  showModal.value = true; 
  try {
    const response = await axios.post(`${BACK_URL.value}/family/family_join`, loginData.value);
    if (response.data.success) {
      const familyResponse = await axios.get(`${BACK_URL.value}/family/family_get_byslug/${loginData.value.family_slug}`);
      const newFamilyId = familyResponse.data.id;
      await settings.logout();


      loginStatus.value = true;
      document.cookie = `family_slug=${loginData.value.family_slug}; path=/; domain=.storycall.org; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      document.cookie = `family_id=${newFamilyId}; path=/; domain=.storycall.org; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      settings.fetchSettings();
      router.push('/home');
    } else {
      loginStatus.value = false;
    }
  } catch (error) {
    console.error('Login error:', error);
    loginStatus.value = false;
  } finally {
    showModal.value = false;
  }
};

onMounted(() => {
  if (Object.keys(ui_string.value).length === 0) {
    settings.initialize();
  }
});
</script>


<style scoped>
.nav-bar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    width: 100%;
    margin-bottom: 15px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

button {
    width: 100%;
    padding: 0.8em 1.2em;
    background-color: #4CAF50;
    /* color: white; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

.success-message {
    color: #4CAF50;
    font-weight: bold;
}

.error-message {
    color: #f44336;
    font-weight: bold;
}

</style>
